<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                    outlined
                    v-model="selectedSite"
                    :items="sites"
                    item-text="name"
                    item-value="vpnid"
                    label="Select Site"
                    :loading="loading"
                    :label="$t('Select site to connect to')"
                    :placeholder="$t('Start typing to Search')"
                    prepend-icon="mdi-database-search"
                    clearable
                    @click:clear="() => {sites = []}"
                    :search-input.sync="search"
                    return-object
                >
                    <template v-slot:selection="data">
                       <v-row><v-col cols="1"><v-icon :color="data.item.online ? 'green' : 'red'" >mdi-access-point</v-icon></v-col>
                        <v-col class="mt-1"><v-list-item-title v-html="data.item.name"></v-list-item-title></v-col></v-row>
                    </template>
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-row><v-col cols="1"><v-icon :color="data.item.online ? 'green' : 'red'" >mdi-access-point</v-icon></v-col>
                        <v-col class="mt-1"><v-list-item-title v-html="data.item.name"></v-list-item-title></v-col></v-row>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-btn color="primary" large class="mt-1"  @click="go">{{$t("SET")}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import store from '../store'

export default {
    data() {
        return {
            selectedSite: null,
            loading: false,
            sites: [], // Replace with your actual site names
            search: null,
        };
    },
    methods: {
        go() {
            // Handle the GO button click event
            // You can access the selected site Vyberte stanici pro připojeníusing this.selectedSite
            console.log("-------------",this.selectedSite)
            const ip = this.selectedSite.vpnip
            this.axios.defaults.headers.common['ProxyPass'] = ip
            this.$connect(this.$wsURL + `?site=${ip}`,
                {
                    store: store,
                    format: 'json',
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 3000,
                    reconnectionDelayMax: 5000,
                    timeout: 20000,
                    pingTimeout: 10000,
                    pingInterval: 5000,
                })
            this.$store.commit('SET_STATION', {})
            this.$store.dispatch('setStation')
            this.$store.dispatch('clearStatuses')
            this.$router.push({ name: 'Home' })
        },

        async init_data() {
            // Initialize the data fetch data from the API and populate the sites array
            this.$disconnect()
            try {
                this.loading = true
                const res = await this.axios.get( this.$serverAPI + "/sites")
                this.sites = res.data
            } catch (e) {
            console.log(e)
            } finally {
            this.loading = false
            }
        }
    },
    mounted() {
        this.init_data()
    },
    watch: {
      async search (val) {
       
        // Items have already been loaded
        if (this.sites.length > 0) return

        // Items have already been requested
        if (this.loading) return

        this.loading = true

        // Lazily load input items
        try {
          const res = await this.axios.get( this.$serverAPI + "/sites")
          this.sites = res.data
          this.loading = false
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }

      }
  },
};
</script>
